@font-face {
  font-family: 'Skape';
  src: url('fonts/Skape.eot?3bmeia');
  src: url('fonts/Skape.eot?3bmeia#iefix') format('embedded-opentype'),
    url('fonts/Skape.ttf?3bmeia') format('truetype'),
    url('fonts/Skape.woff?3bmeia') format('woff'),
    url('fonts/Skape.svg?3bmeia#Skape') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Skape' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-page:before {
  content: "\e901";
}

.icon-clean:before {
  content: "\e922";
}

.icon-update:before {
  content: "\e923";
}

.icon-menu {
  font-size: 75%;
}

.icon-menu:before {
  content: "\e924";
}

.icon-time:before {
  content: "\e925";
}

.icon-heart-2:before {
  content: "\e900";
}

.icon-search-2:before {
  content: "\e921";
}

.icon-heart:before {
  content: "\e902";
}

.icon-quote:before {
  content: "\e903";
}

.icon-reply:before {
  content: "\e904";
}

.icon-search:before {
  content: "\e905";
}

.icon-corner-down-right:before {
  content: "\e906";
}

.icon-cross:before {
  content: "\e907";
}

.icon-plus:before {
  content: "\e908";
}

.icon-toolbox:before {
  content: "\e909";
}

.icon-star:before {
  content: "\e90a";
}

.icon-reward:before {
  content: "\e90b";
}

.icon-protactor:before {
  content: "\e90c";
}

.icon-play:before {
  content: "\e90d";
}

.icon-pin:before {
  content: "\e90e";
}

.icon-paint-bucket:before {
  content: "\e90f";
}

.icon-layers:before {
  content: "\e910";
}

.icon-image:before {
  content: "\e911";
}

.icon-helmet:before {
  content: "\e912";
}

.icon-grid:before {
  content: "\e913";
}

.icon-focus:before {
  content: "\e914";
}

.icon-envelope:before {
  content: "\e915";
}

.icon-drill:before {
  content: "\e916";
}

.icon-drawing-tools:before {
  content: "\e917";
}

.icon-check:before {
  content: "\e918";
}

.icon-case:before {
  content: "\e919";
}

.icon-camera:before {
  content: "\e91a";
}

.icon-box:before {
  content: "\e91b";
}

.icon-arrow-top-right:before {
  content: "\e91c";
}

.icon-arrow-right:before {
  content: "\e91d";
}

.icon-arrow-left:before {
  content: "\e91e";
}

.icon-arrow-down:before {
  content: "\e91f";
}

.icon-anchor:before {
  content: "\e920";
}