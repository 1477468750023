.scrollDiv::-webkit-scrollbar {
  height: 0px;
  width: 5px;
  background-color: transparent;
}

.scrollDivV::-webkit-scrollbar {
  width: 3px;
}

.scrollDiv::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(19, 19, 19, 0); */
  border: none;
  border-radius: 50px;
  margin: 0 0px;
}

.scrollDiv::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(107, 110, 113, 0.653);
}