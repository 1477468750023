@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


@keyframes slideInFromLeft {
  0% {
    opacity: 0;
    /* transform: translateX(-7%); */
  }

  100% {
    opacity: 1;
    /* transform: translateX(0); */
  }
}

.subHeading {
  font-family: Swansea, sans-serif;
  font-weight: bold;

}

.heading {
  font-family: Swansea, sans-serif;
  font-weight: bold;

}

.renderAnimate {
  /* This section calls the slideInFromLeft animation we defined above */
  animation: 300ms ease-out 0s 1 slideInFromLeft;
}

.gradientGreen {
  background: -webkit-linear-gradient(rgb(1, 255, 35), rgb(12, 17, 29));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gridC {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, 186px);
}

::-moz-selection {
  /* Code for Firefox */
  color: white;
  background: rgb(44, 200, 190);
}

::selection {
  color: white;
  background: rgb(44, 200, 190);
}

/* ::-webkit-scrollbar{
  width: 2px;
}
::-webkit-scrollbar-track{
  background: transparent;
}
::-webkit-scrollbar-thumb{
  background: transparent;
} */
* {
  font-family: Swansea, sans-serif;


}

body {
  overflow-x: hidden !important;
  font-family: 'Poppins', sans-serif !important;
  line-height: 1.5rem;
  background-color: white;

}

@font-face {
  font-family: "GILLORY";
  src: url("./assets/fonts/FontsFree-Net-Gilroy-Bold.ttf");
}

#root {
  overflow-x: hidden !important;
}

.swiper-button-next,
.swiper-button-prev {

  right: 10px;
  padding: 30px;
  color: rgba(0, 0, 0, 0.168) !important;
  width: 2px !important;
  height: 2px !important;
}

@media screen and (max-width:768px) {

  .swiper-button-next,
  .swiper-button-prev {

    color: rgba(0, 0, 0, 0.047) !important;
  }
}