

.fill{
    animation: fill_color 3s ease-out;
}

@keyframes fill_color {
	0%   {
		transform: translateX(-100%);
        /* stroke-width: 2; */
	}
	/* 70%  {fill: rgba(72,138,20,0); stroke: #000000; }
	80%  {fill: rgba(72,138,20,0); stroke: #000000; stroke-width: 3; } */
	100% {
		transform: translateX(0);
	}
}
