@font-face {
  font-family: "Scalter-SerifCondensed";
  src: url("./fonts/Scalter-SerifCondensed.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.workshops-page {
  background-color: #190024;
  min-height: 100vh;
  padding: 0;
  color: white;
}

.top-nav {
  position: fixed;
  top: 0;
  right: 0;
  padding: 20px;
  z-index: 1000;
  background-color: transparent;
}

.top-nav::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: -1;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.top-nav:hover::before {
  opacity: 1;
}

.nav-links {
  display: flex;
  gap: 2rem;
}

.nav-link {
  color: white;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #ffd700;
}

.hero-section {
  height: 80vh;
  background-image: url("../assets/competitionBg.webp"); /* Updated path */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  position: relative;
}

.hero-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(
    0,
    0,
    0,
    0.5
  ); /* Adds a dark overlay to make text more readable */
}

.page-title {
  position: relative;
  z-index: 1;
  text-align: center;
  font-size: 8rem;
  margin: 0;
  font-weight: 400;
  font-family: "Scalter-SerifCondensed", sans-serif;
  letter-spacing: 5px;
  color: #ffd700; /* Solid yellow color */
  -webkit-text-stroke: 2px black; /* Black border for webkit browsers */
  text-stroke: 2px black; /* Black border for other browsers */
  animation: glitch 1.5s infinite alternate-reverse;
}
@media screen and (max-width: 550px) {
  .page-title {
    font-size: 3rem;
  }
}

@keyframes glitch {
  2%,
  64% {
    transform: translate(4px, 0) skew(2deg);
  }
  4%,
  60% {
    transform: translate(-4px, 0) skew(-2deg);
  }
  62% {
    transform: translate(0, 0) skew(8deg);
  }
}

.page-title:hover {
  animation: glitch 0.2s infinite;
}

.page-title::before,
.page-title::after {
  content: "WORKSHOPS";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  background: transparent;
  -webkit-text-stroke: 2px black;
  text-stroke: 2px black;
}

.page-title::before {
  left: 4px;
  color: #000000;
  animation: glitch-1 1s infinite linear alternate-reverse;
}

.page-title::after {
  left: -4px;
  color: #ffd700;
  animation: glitch-2 1.5s infinite linear alternate-reverse;
}

@keyframes glitch-1 {
  0% {
    clip-path: inset(40% 0 60% 0);
  }
  20% {
    clip-path: inset(80% 0 5% 0);
  }
  40% {
    clip-path: inset(35% 0 68% 0);
  }
  60% {
    clip-path: inset(95% 0 5% 0);
  }
  80% {
    clip-path: inset(5% 0 98% 0);
  }
  100% {
    clip-path: inset(75% 0 33% 0);
  }
}

@keyframes glitch-2 {
  0% {
    clip-path: inset(92% 0 12% 0);
  }
  20% {
    clip-path: inset(43% 0 58% 0);
  }
  40% {
    clip-path: inset(97% 0 3% 0);
  }
  60% {
    clip-path: inset(5% 0 79% 0);
  }
  80% {
    clip-path: inset(85% 0 27% 0);
  }
  100% {
    clip-path: inset(20% 0 93% 0);
  }
}

.date-filters {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin: 2rem 0;
}

.date-button {
  padding: 8px 20px;
  border: 1px solid #ffffff;
  background: transparent;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.date-button.active {
  background-color: #ffd700;
  border-color: #ffd700;
  color: black;
}

.workshop-cards {
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
  padding: 2rem;
}
@media screen and (max-width: 400px) {
  .workshop-cards {
    padding: 0px;
  }
}

.workshop-card {
  background-color: #000000;
  border-radius: 15px;
  padding: 1rem;
  width: 300px;
  transition: transform 0.3s ease;
}

.workshop-card:hover {
  transform: translateY(-5px);
}

.card-image {
  width: 100%;
  height: 200px;
  background-color: #333;
  border-radius: 10px;
  margin-bottom: 1rem;
}

.workshop-card h2 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.divider {
  height: 3px;
  background: repeating-linear-gradient(
    to right,
    #fff 0,
    #fff 5px,
    transparent 5px,
    transparent 10px
  );
  margin: 0.5rem 0;
}

.workshop-card p {
  font-size: 0.9rem;
  color: #cccccc;
  margin-bottom: 1rem;
}

.check-out-btn {
  width: 100%;
  padding: 10px;
  background-color: #c4a484;
  border: none;
  border-radius: 5px;
  color: black;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.check-out-btn:hover {
  background-color: #b08968;
}

/* Event CSS */
.event-page {
  background-color: #190024;
  min-height: 100vh;
  padding: 2rem;
  padding-top: 8rem;
}

@media screen and (max-width: 400px) {
  .event-page {
    padding: 20px;
  }
}
.event-container {
  max-width: 800px;
  margin: 0 auto;
  background: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.event-image {
  width: 100%;
  margin-bottom: 2rem;
}

.event-image img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.event-title {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  color: #000;
}
@media screen and (max-width: 500px) {
  .event-title {
    font-size: 20px;
  }
}

.event-description {
  color: #555;
  line-height: 1.6;
  margin-bottom: 2rem;
}

.event-details {
  margin-bottom: 2rem;
}

.detail-section {
  margin-bottom: 1.5rem;
}

.event-timing {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}
@media screen and (max-width: 400px) {
  .event-timing {
    grid-template-columns: auto;
  }
}

.detail-item {
  background: #f8f8f8;
  padding: 1rem;
  border-radius: 6px;
}

.detail-item h3 {
  color: #666;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.detail-item p {
  color: #000;
  font-weight: 500;
}

.registration-section {
  border-top: 2px solid #eee;
  padding-top: 2rem;
}

.registration-section h2 {
  margin-bottom: 1.5rem;
  color: #000;
}

.registration-details {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-bottom: 2rem;
}

.action-buttons {
  display: flex;
  gap: 1rem;
}

.register-btn,
.guidelines-btn {
  padding: 0.8rem 2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
}

.register-btn {
  background: #000;
  color: white;
}

.guidelines-btn {
  background: #f0f0f0;
  color: #000;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.register-btn:hover {
  background: #333;
}

.guidelines-btn:hover {
  background: #e0e0e0;
}
